$window.on('load', function () {
    // Get Navigation Node from the page
    var NavNode = Geonetric.Page.NavigationNode;
        
    // Get Location id from the cmspage
	if ($('#LocationId').length) {
		
        var ThisLocationId = $('#LocationId').text(),
            locationsArray = ThisLocationId.split(', '),
            markersArray = [],
            locId = [],
            locTermId = [],
            count = locationsArray.length;
		
//		if (count > 2) {
//			$('.RelatedLocations').wrapInner('<ul class="LocationWrap"></ul>').prepend('<h3 class="sr-only">Related Locations</h3>');
//		} else {
//			$('.RelatedLocations').prepend('<h3 class="sr-only">Related Location</h3>');
//		}

        // Add container for related locations if there is more than one location
        if (count > 1) { 
            var RelatedLocations = '';
            if (count == 2) {
                RelatedLocations = '<div class="RelatedLocations"><h3 class="sr-only">Related Location</h3><ul class="LocationWrap"></ul></div>';
            } else {
                var RelatedLocations = '<div class="RelatedLocations"><h3 class="sr-only">Related Locations</h3><ul class="LocationWrap"></ul></div>';
            }
           
            $('.LocationInfoWrapper .LocationInfo').append(RelatedLocations); 
            
        } 
        
		$(locationsArray).each(function (index) {
			// Pull in data from nancy
			$.get('/nancy/locations/'+locationsArray[index]+'/viewmodel.json', function (data) {
			
                //Loop through address array
				var locNameText = (data.Name),
					locAddress = (data.Address.AddressLine1),
					locAddress2 = (data.Address.AddressLine2),
					locCity = (data.Address.City),
                    locState = (data.Address.StateAbbreviation),
					locStateName = (data.Address.StateName),
                    locZip = (data.Address.PostalCode),
					drivingDirections = (data.GoogleDrivingDirectionsUrl),
					locImage = '',
					locImageFile = (data.Images[0] && data.Images[0].PublicHRef ? data.Images[0].PublicHRef: null),
                    webSiteLink = (data.WebSiteUrl);
					
				// Get other phone numbers
				var phoneMain = (data.PhoneNumbers.Main.WholeNumber),
					phoneAlt = (data.PhoneNumbers.Alternate.WholeNumber),
					phoneOffice = (data.PhoneNumbers.Office.WholeNumber),
					phoneMobile = (data.PhoneNumbers.Mobile.WholeNumber),
					phoneFax = (data.PhoneNumbers.Fax.WholeNumber),
                    phonePager = (data.PhoneNumbers.Pager.WholeNumber);

				// Get other location information
				var locOldHours = (data.HoursOfOperation),
					locNewHours = (data.OfficeHoursList),
					locComment = (data.OfficeHourComment),
					locLat = (data.Latitude),
					locLong = (data.Longitude);

				// Get Location IDs
				locId.push(data.LocationId);
				locTermId.push(data.Term.TermId);
					
				// Add the data we need for Google Maps to an array
				// i+1 is the zindex for each location
				markersArray.push([locNameText, locLat, locLong, index+1, locAddress, locAddress2, locCity, locState, locZip, phoneMain]);
                                
                if (locImageFile !== null) { 
                    locImage  = '<img src="'+ locImageFile.replace('~', '') +'" alt="" itemprop="image" />';
                } 
                
                if (drivingDirections !== null) { 
					drivingDirections  = '<a target="_blank" class="DDLink" href="'+ drivingDirections +'">Get Driving Directions<span class="sr-only"> to '+ locNameText +', opens new tab</span></a>';
				} 
                
                var ContactInformation = '',
                        PhoneNumbers = '',
                        Hours = '';
                
                if (index == 0) {
                    ContactInformation = '<h3>Contact Information</h3>' +
                    '<div class="vcard">';
                        if (locImage.length) {
                            ContactInformation = ContactInformation + locImage;    
                        }
                        
                        ContactInformation = ContactInformation + '<div class="adr">';
                        
                        if (webSiteLink.length) {
                            ContactInformation += '<p><strong><a href="'+ webSiteLink +'">' + locNameText + '</a></strong>';
                        } else {
                            ContactInformation += '<p><strong>' + locNameText + '</strong>';
                        }
                    
                        if (locAddress.length) {
                            ContactInformation = ContactInformation + '<span class="street-address">' + locAddress + '</span>';
                        }
                        if (locAddress2.length) {
                            ContactInformation = ContactInformation + '<span class="street-address">' + locAddress2 + '</span>';
                        }
                        if (locCity.length && locState.length && locZip.length) {
                            ContactInformation = ContactInformation + '<span class="locality">'+ locCity +'</span><text>,</text> <span class="region"><abbr title="'+ locStateName +'">'+ locState +'</abbr></span> <span class="postal-code">'+ locZip +'</span>';
                        } else {
                            if (locCity.length) {
                                ContactInformation = ContactInformation + '<span class="locality">'+ locCity +',&nbsp;</span>';
                            }
                            if (locState.length) {
                                ContactInformation = ContactInformation + '<span class="region"><abbr title="'+ locStateName +'">'+ locState +'</abbr>&nbsp;</span>';
                            }    
                            if (locZip.length) {
                                ContactInformation = ContactInformation + '<span class="postal-code">'+ locZip +'</span>';
                            }   
                        }
                        if (drivingDirections.length) {
                            ContactInformation = ContactInformation + '</p><p>' + drivingDirections;
                        }
                    
                    ContactInformation = ContactInformation + '</p></div></div>';
                
                    if (phoneMain !== null || phoneAlt !== null || phoneOffice !== null || phoneMobile !== null || phoneFax !== null || phonePager !== null) {

						PhoneNumbers = '<ul class="Plain PhoneNumbers">';

						if (phoneMain !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="MainTelephoneNumber">' +
								'<span class="TelLabel Hide"><strong>Main:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneMain + '" aria-label="Call on ' + phoneMain + '">' + phoneMain + '</a></span>' +
								'</li>';
						}
						if (phoneAlt !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="AlternateTelephoneNumber">' +
								'<span class="TelLabel"><strong>Alternate:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneAlt + '" aria-label="Call on ' + phoneAlt + '">' + phoneAlt + '</a></span>' +
								'</li>';
						}
						if (phoneOffice !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="OfficeTelephoneNumber">' +
								'<span class="TelLabel"><strong>Office:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneOffice + '" aria-label="Call on ' + phoneOffice + '">' + phoneOffice + '</a></span>' +
								'</li>';
						}
						if (phoneMobile !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="MobileTelephoneNumber">' +
								'<span class="TelLabel"><strong>Mobile:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneMobile + '" aria-label="Call on ' + phoneMobile + '">' + phoneMobile + '</a></span>' +
								'</li>';
						}
						if (phoneFax !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="MobileTelephoneNumber">' +
								'<span class="TelLabel"><strong>Fax:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phoneFax + '" aria-label="Fax at ' + phoneFax + '">' + phoneFax + '</a></span>' +
								'</li>';
						}
						if (phonePager !== null) {
							PhoneNumbers = PhoneNumbers + '<li class="PagerNumber">' +
								'<span class="TelLabel"><strong>Pager:</strong></span> ' +
								'<span class="tel" itemprop="telephone"><a href="tel:' + phonePager + '">' + phonePager + '</a></span>' +
								'</li>';
						}
						PhoneNumbers = PhoneNumbers + '</ul>';
					}

                    // See if location has new hours set and times exist
                    var hasNewHours = false;

                    for (var day in locNewHours) {
                        if (locNewHours[day].OfficeHours.length > 0) {
                            hasNewHours = true;
                        }
                    }
                    
                    // Add office hours markup
                    if (hasNewHours) {
                        Hours = '<div class="Hours NewHours"><h3>Business Hours</h3>';
                        
                        for (day in locNewHours) {
                            var theHours = locNewHours[day].OfficeHours;

                            if (theHours.length === 0) {
                                Hours += '<div class="DaySchedule">' +
                                    '<div class="Day">' + day + '</div>' +
                                    '<div class="Times">' + 'Closed' + '</div>' +
                                '</div>';
                            } else {
                                for (var i = 0; i < theHours.length; i++ ) {
                                    Hours += '<div class="DaySchedule">' +
                                        '<div class="Day">' + day + '</div>' +
                                        '<div class="Times">' + convertHours(theHours[i].OpenTime) + '<div class="RangeSeparator"> - </div>' + convertHours(theHours[i].CloseTime) + '</div>' +
                                    '</div>';
                                }
                            }
                        }
                        
                        if (locComment.length) {
                            Hours += '<div class="OfficeHourComment">' + locComment + '</div>';
                        }
                        
                        Hours += '</div>';
                    } else if (locOldHours.length && locComment.length ) {
                         Hours = '<div class="Hours"><h3>Business Hours</h3>';
                            Hours += '<div class="OfficeHourComment">' + locComment + '</div>';
                         Hours += '</div>';
                    } else if (hasNewHours == false && locComment.length) {
                        Hours = '<div class="Hours"><h3>Business Hours</h3>';
                            Hours += '<div class="OfficeHourComment">' + locComment + '</div>';
                        Hours += '</div>';
                    } else {
                        Hours = '<div class="Hours"><h3>Business Hours</h3>';
                            Hours += '<div class="DaySchedule">'+ locOldHours +'</div>';
                        Hours += '</div>';
                    }

                    // Convert 24 hour times (16:00) to 12 hour AM/PM times (4:00 PM)
                    function convertHours(time) {
                        // Check correct time format and split into components
                        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

                        if (time.length > 1) { // If time format correct
                            time = time.slice (1);  // Remove full string match value
                            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                            time[0] = +time[0] % 12 || 12; // Adjust hours
                        }
                        return time.join (''); // return adjusted time or original string
                    }    
                   
                    $('.LocationInfoWrapper .ContactInfo:first').append(ContactInformation, PhoneNumbers);
                    $(Hours).insertAfter('.LocationInfoWrapper .ContactInfo:first');
                    
                    // highlight current day of week for business hours 
                    var FindDate = new Date();
                    var WeekDays = FindDate.getDay();
                    $('.LocationInfo .Hours .DaySchedule, .LocationInfo .Hours .HoursOfOperation').eq(WeekDays).addClass('TodaysWeekday');

                    if (WeekDays == 0) {
                        $('.Day:contains("Sunday"), .DayOfWeek:contains("Sunday")').addClass('Today');
                    } else if (WeekDays == 1) {
                        $('.Day:contains("Monday"), .DayOfWeek:contains("Monday")').addClass('Today');
                    } else if (WeekDays == 2) {
                        $('.Day:contains("Tuesday"), .DayOfWeek:contains("Tuesday")').addClass('Today');
                    } else if (WeekDays == 3) {
                        $('.Day:contains("Wednesday"), .DayOfWeek:contains("Wednesday")').addClass('Today');
                    } else if (WeekDays == 4) {
                        $('.Day:contains("Thursday"), .DayOfWeek:contains("Thursday")').addClass('Today');
                    } else if (WeekDays == 5) {
                        $('.Day:contains("Friday"), .DayOfWeek:contains("Friday")').addClass('Today');
                    } else {
                        $('.Day:contains("Saturday"), .DayOfWeek:contains("Saturday")').addClass('Today');
                    }
                    
                } else {
                    ContactInformation = '<li class="ContactInfo">'+
                    '<div class="vcard">';
                        ContactInformation += '<div class="adr">';
                    
                        if (locImage.length) {
                            if (webSiteLink.length) {
                                ContactInformation += '<strong><a href="'+ webSiteLink +'">' + locImage + '<span class="Name">' + locNameText + '</span></a></strong>';
                            } else {
                                ContactInformation += '<strong><a href="/app/locations/profile.aspx?navigationNode='+ NavNode +'&amp;id='+ (data.LocationId) +'">'+ locImage +'<span class="Name">' + locNameText + '</span></a></strong>';
                            }
                        } else {
                            if (webSiteLink.length) {
                                ContactInformation += '<strong><a href="'+ webSiteLink +'"><span class="Name">' + locNameText + '</span></a></strong>';
                            } else {
                                ContactInformation += '<strong><a href="/app/locations/profile.aspx?navigationNode='+ NavNode +'&amp;id='+ (data.LocationId) +'"><span class="Name">' + locNameText + '</span></a></strong>';
                            }
                        }
                        
                        if (locAddress.length) {
                            ContactInformation += '<p><span class="street-address">' + locAddress + '</span>';
                        }
                        if (locAddress2.length) {
                            ContactInformation += '<span class="street-address">' + locAddress2 + '</span>';
                        }
                        if (locCity.length && locState.length && locZip.length) {
                            ContactInformation += '<span class="locality">'+ locCity +'</span><text>,</text> <span class="region"><abbr title="'+ locStateName +'">'+ locState +'</abbr></span> <span class="postal-code">'+ locZip +'</span>';
                        } else {
                            if (locCity.length) {
                                ContactInformation += '<span class="locality">'+ locCity +'</span>';
                            }
                            if (locState.length) {
                                ContactInformation += '<span class="region"><abbr title="'+ locStateName +'">'+ locState +'</abbr></span>';
                            }    
                            if (locZip.length) {
                                ContactInformation += '<span class="postal-code">'+ locZip +'</span>';
                            }   
                        }
                        ContactInformation += '</p>';
                    
                        if (drivingDirections.length) {
                            ContactInformation += drivingDirections;
                        }
                    
                    ContactInformation += '</div>';
                
                    if (phoneMain !== null) {

                        PhoneNumbers = '<div class="Contact"><p class="Phone">';

                        if (phoneMain !== null) {
                            PhoneNumbers +='<span class="sr-only">Phone: </span> '+
                                '<a href="tel:'+ phoneMain +'">'+ phoneMain +'</a>'; 
                        }
                       
                        PhoneNumbers += '</p></div>';
                    }
                    
                    ContactInformation += PhoneNumbers + '</li>';
                    
                    $('.RelatedLocations .LocationWrap').append(ContactInformation);
                }
                
                // After everything is finished running
				if(markersArray.length === locationsArray.length) {
					// Remove preloader image
					$('.RelatedLocations #loadingmessage').remove();
					
					initializeMap(markersArray);

					// Turn Array into String 
                    var locTermIdString = locTermId.join();
                    
					googleMapHeight();
				}
            });
    	}); 		
	} // end each locations function
    
    setTimeout(function() {
        googleMapHeight();
    }, 1000);

});